.table {
  & th,
  & td {
    vertical-align: middle !important;
  }
}
.navbar-toggler {
  display: none;
}
.loading {
  position: fixed;
  z-index: 99999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.home-profile-image-edit {
  position: absolute;
  top: 58px;
  width: 20px;
  height: 20px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}
.card-main {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  max-height: 75vh;
  overflow-y: auto;
}
.uploadbtn {
  background-color: #dddada !important;
  margin-bottom: 5px;
  transition: all 0.1s ease-in;
}
.uploadbtn:hover {
  background-color: #ebe8e8 !important;
}
.video-list {
  background-color: #ededed !important;
  margin-bottom: 5px;
  transition: all 0.1s ease-in;
}
.certificate {
  color: rgb(233, 43, 75) !important;
}
.myphygital {
  color: rgb(43, 46, 233) !important;
}
.video {
  color: rgb(71, 161, 71) !important;
}
.photo {
  color: rgb(202, 15, 71) !important;
}
.resume {
  color: rgb(20, 109, 160) !important;
}
.testimonial {
  color: rgb(20, 160, 148) !important;
}
.social-link {
  color: rgb(162, 149, 236) !important;
}
.services {
  color: rgb(233, 149, 236) !important;
}
.facility {
  color: rgb(204, 158, 58) !important;
}
.about {
  color: rgb(160, 73, 46) !important;
}
.contactInfo {
  color: rgb(46, 139, 226) !important;
}
.pac-container {
  z-index: 99999 !important;
}
