.likes__wrapper{
    display:flex;
    align-content:center;
    justify-content:center;
     
  }
.likes__relavance{
    position:relative;
    padding:0 80px;
  }
  .likes__list{
    position:absolute;
    box-sizing: border-box;
    overflow-y:scroll;
    max-height:150px;
    left:0%;
    z-index:999;
    background:white;
    padding:10px;
    border:1px solid grey;
    box-shadow: 0 0 2px 2px grey;
  }

Popover {
	position:relative;
}